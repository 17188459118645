import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, Link } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
const $ = require("jquery")
$.Datatable = require("datatables.net");
// import "../transaction/imageview.css"


export default function ListTournaments() {
  const [data, setData] = useState([])
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }


  //use for pagination..
  let [limit, setLimit] = useState(10);

  const setpageLimit = (event) => {
    let key = event.target.value
    setLimit(key);
  }
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);

  //react paginate..
  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    setPageNumber(currentPage);
  };

  const All = () => {
    const access_token = localStorage.getItem("token")
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    axios.get(baseUrl + `tournament-list?page=${pageNumber}&_limit=${limit}`, { headers })
      .then((res) => {
        setData(res.data);
        setNumberOfPages(res.data.totalPages)

      })
  }


  const handleToggle = (id, currentState) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`
    };
    const newState = currentState === 1 ? 0 : 1;
    axios
      .post(
        `${baseUrl}tournament-status-update`,
        { id, status: newState },
        { headers }
      )
      .then((res) => {
        if (res.status === 200) {
          setData((prevData) =>
            prevData.map((item) =>
              item._id === id ? { ...item, status: newState } : item
            )
          );
        }
      })
      .catch((err) => {
        console.error("Error toggling status:", err);
      });
  };


  const handleStatus = (id, currentState) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`
    };
    const newState = currentState === "complete" ? "pending" : "complete";
    axios
    .post(
      `${baseUrl}tournament-status-update-complete`,
        { id, tournamentComplete: newState },
        { headers }
      )
      .then((res) => {
        if (res.status === 200) {
          setData((prevData) =>
            prevData.map((item) =>
              item._id === id ? { ...item, tournamentComplete: newState } : item
            )
          );
        }
      })
      .catch((err) => {
        console.error("Error toggling status:", err);
      });
  };

  const JoinerStatus = (id, currentState) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`
    };
    const newState = currentState === "yes" ? "no" : "yes";
    axios
    .post(
      `${baseUrl}tournament-status-update-user-play`,
        { id, user_join: newState },
        { headers }
      )
      .then((res) => {
        if (res.status === 200) {
          setData((prevData) =>
            prevData.map((item) =>
              item._id === id ? { ...item, user_join: newState } : item
            )
          );
        }
      })
      .catch((err) => {
        console.error("Error toggling status:", err);
      });
  };



  useEffect(() => {
    All()
  }, [pageNumber, limit])

  const deletePlayer = (player) => {
    const confirmBox = window.confirm(`Are you sure you want to delete ${player}?`);
    if (confirmBox) {
      const access_token = localStorage.getItem("token");
      if (!access_token) {
        alert("Please login to proceed.");
        return;
      }

      const headers = {
        Authorization: `Bearer ${access_token}`,
      };

      axios
        .post(
          `${baseUrl}tournament/delete/${player}`,
          { player },
          { headers }
        )
        .then((res) => {
          console.log("Response:", res);
          if (res.status === 200 || res.status === 204) {
            All();
            alert(`Player ${player} deleted successfully.`);
          } else {
            alert("Failed to delete the player. Unexpected response from the server.");
          }
        })
        .catch((err) => {
          console.error("Error deleting player:", err);
          alert("Failed to delete the player. Please try again.");
        });
    }
  };


  const dateFormat = (e) => {
    const date = new Date(e);
    const newDate = date.toLocaleString('default', { month: 'long', day: 'numeric', hour: 'numeric', hour12: true, minute: 'numeric' });
    return newDate;
  }

  return (
    <>
  <div className='d-flex justify-content-end mb-3'>
    <Link to="/Tournament/AddTournaments"> 
      <button className='btn btn-primary'>Add Tournament</button>
      </Link>
      </div>
      {/* <h4 className='font-weight-bold my-3'>ALL CHALLANGES</h4> */}
      <div className="row ">
        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body">
              <div className="img-out"></div>
              <h4 className="card-title">Tournament List</h4>

              <select className='form-control col-sm-2 m-1' id='pagelimit' name='pagelimit' onChange={setpageLimit}>
                <option value="10">Set limit</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="500">500</option>
              </select>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>ID</th>
                      <th>Entry Fees</th>
                      <th>Total Slot</th>
                      <th>Start Time</th>
                      <th>End Time</th>
                      <th>Tid</th>
                      <th>Date</th>
                      <th>CreatedAt</th>
                      <th>Tournament ON/OFF</th>
                      <th>Tournament Complete/Pending</th>
                      <th>User Join Access</th>
                      <th>Add Prize</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item._id}</td>
                          <td>{item.entry_fees}</td>
                          <td>{item.total_user}</td>
                          <td>{item.start_time}</td>
                          <td>{item.end_time}</td>
                          <td>{item.t_id}</td>
                          <td>{item.date}</td>
                          <td>{dateFormat(item.createdAt).split(",")[0]}</td>
                          <td>
                            <button
                              onClick={() => handleToggle(item._id, item.status)}
                              className={`btn ${item.status === 1 ? "btn-success" : "btn-danger"}`}
                            >
                              {item.status === 1 ? "On" : "Off"}
                            </button>
                          </td>
                          <td>
                            <button
                              onClick={() => handleStatus(item._id, item.tournamentComplete)}
                              className={`btn ${item.tournamentComplete === "complete" ? "btn-success" : "btn-danger"}`}
                            >
                              {item.tournamentComplete === "complete" ? "Complete" : "Pending"}
                            </button>
                          </td>

                          <td>
                            <button
                              onClick={() => JoinerStatus(item._id, item.user_join)}
                              className={`btn ${item.user_join === "yes" ? "btn-success" : "btn-danger"}`}
                            >
                              {item.user_join === "yes" ? "yes" : "no"}
                            </button>
                          </td>

                          <td>
                            <Link to={`/Tournament/AddPrize/?t_id=${item.t_id}`}>
                              <button className='btn btn-primary'>Add Prize</button>
                            </Link>
                          </td>

                          <td>
                            <button type="button"
                              onClick={() => {
                                deletePlayer(item._id);
                              }}
                            >
                              Delete
                            </button></td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>

              <div className='mt-4'>
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  pageCount={numberOfPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}
