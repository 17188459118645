import axios from 'axios';
import React, { useEffect, useState } from 'react';
import callimg from "../../../assets/images/whatsapp.png";
const $ = require("jquery");
$.Datatable = require("datatables.net");


export default function PairTournaments() {

  const [tournaments, setTournaments] = useState([]);
  const [tournaments1, setTournaments1] = useState([]);
  const [selectedTournament, setSelectedTournament] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [secondUsers, setSecondUsers] = useState([]);
  const [selectedSecondUser, setSelectedSecondUser] = useState(null);
  const [gameAmount, setGameAmount] = useState('');
  const [challenge, setchallenge] = useState();
  const [txn, setTxn] = useState();
  const [winners, setWinner] = useState();
  const [prizes, setPrizes] = useState({});
  const [level, setlevel] = useState([]);

  const backendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const backendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl = nodeMode === "development" ? backendLocalApiUrl : backendLiveApiUrl;

  const fetchTournaments = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    try {
      const response = await axios.get(baseUrl + 'tournament-active-list', { headers });
      setTournaments(Array.isArray(response.data.TournamentModeldata) ? response.data.TournamentModeldata : []);
      setGameAmount(response.data.TournamentModeldata[0].entry_fees);
      setTournaments1(response.data.TournamentModeldata[0].t_id);
    } catch (error) {
      console.error("Error fetching tournaments:", error);
      setTournaments([]);
    }
  };

  const fetchUsers = async (tournamentId) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    try {
      const response = await axios.post(
        baseUrl + 'tournament-join-list',
        { tournament_id: tournamentId },
        { headers }
      );
      setUsers(Array.isArray(response.data.TournamentModeldata) ? response.data.TournamentModeldata : []);
    } catch (error) {
      console.error("Error fetching users:", error);
      setUsers([]);
    }
  };


  const fetchSecondUsers = async (tournamentId, userId) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    try {
      const response = await axios.post(
        baseUrl + 'tournament-join-list-second',
        { tournament_id: tournamentId, userid: userId },
        { headers }
      );
      setSecondUsers(Array.isArray(response.data.TournamentModeldata) ? response.data.TournamentModeldata : []);
    } catch (error) {
      console.error("Error fetching second users:", error);
      setSecondUsers([]);
    }
  };

  const handlePair = async (event) => {
    event.preventDefault();
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    const payload = {
      creater_userid: selectedUser,
      joiner_userid: selectedSecondUser,
      Game_Ammount: gameAmount,
      tournament_id: selectedTournament,
    };

    try {
      const response = await axios.post(baseUrl + 'tournament-user-pair', payload, { headers });
      window.location.reload();
    } catch (error) {
      console.error("Error pairing users:", error);
      alert("Error pairing users. Please try again.");
    }
  };

  useEffect(() => {
    fetchTournaments();
  }, []);


  const Allchallenge = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(baseUrl + `tournament-pair-and-join-user-list`, { tournament_id: selectedTournament }, { headers })
      .then((res) => {
        setTxn(undefined);
        setWinner(undefined);
        setchallenge(res.data.pairedlist);
        setlevel(undefined);
      })
      .catch((e) => {
        console.log(e);
      });
  };


  const transactionHis = () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(baseUrl + `tournament-pair-and-join-user-list`, { tournament_id: selectedTournament }, { headers })
      .then((res) => {
        setTxn(res.data.Joinlist);
        setWinner(undefined);
        setchallenge(undefined);
        setlevel(undefined);
      })
      .catch((e) => {
        console.log(e);
      });
  };


  const LevelWise = () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(baseUrl + `tournament-lavel-wise-user-list`, { tournament_id: selectedTournament }, { headers })
      .then((res) => {
        setlevel(res.data.levelCounts);
        setTxn(undefined);
        setWinner(undefined);
        setchallenge(undefined);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    Allchallenge();
  }, []);


  const winnerUsers = () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(baseUrl + `tournament-pair-and-join-user-list`, { tournament_id: selectedTournament }, { headers })
      .then((res) => {
        setWinner(res.data.winnerlist);
        const allWinnerPrizes = res.data.winnerlist.reduce((acc, winner) => {
          acc[winner._id] = winner.winnerPrize;
          return acc;
        }, {});

        setPrizes(allWinnerPrizes);
        console.log("All winnerPrizes: ", allWinnerPrizes);
        setTxn(undefined);
        setchallenge(undefined);
        setlevel(undefined);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    Allchallenge();
  }, []);

  const updatePrizeChange = (id, e) => {
    const value = e.target.value;
    setPrizes((prevPrizes) => ({
      ...prevPrizes,
      [id]: value,
    }));
  };

  const updatePrize = (id, winnerPrize, userid) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    const data = {
      tournament_id: tournaments1,
      id: id,
      winnerPrize: winnerPrize,
      userid: userid
    };

    axios
      .post(baseUrl + `tournament-join-wiiner-prize`, data, { headers })
      .then((res) => {
        if (res.data) {
          setWinner(res.data.winnerlist);
          alert('Prize updated successfully!');
        } else {
          alert('Failed to update prize. Please try again.');
        }
      })
      .catch((e) => {
        console.log(e);
        alert('An error occurred while updating the prize.');
      });
  };

  return (
    <div className="row">
      <div className="col-12 grid-margin">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">Pair Tournament</h4>
            <div className="form-group">
              <label>Select Tournament</label>
              <select
                className="form-control"
                value={selectedTournament || ''}
                onChange={async (e) => {
                  const tournamentId = e.target.value;
                  setSelectedTournament(tournamentId);
                  await fetchUsers(tournamentId);
                }}
              >
                <option value="">Select a tournament</option>
                {Array.isArray(tournaments) && tournaments.map((tournament) => (
                  <option key={tournament.t_id} value={tournament.t_id}>
                    {tournament.t_id}
                  </option>
                ))}
              </select>
            </div>

            {users.length > 0 && (
              <div className="form-group">
                <label>Select User First</label>
                <select
                  className="form-control"
                  value={selectedUser || ''}
                  onChange={(e) => {
                    const userId = e.target.value;
                    setSelectedUser(userId);
                    if (selectedTournament) {
                      fetchSecondUsers(selectedTournament, userId);
                    }
                  }}
                >
                  <option value="">Select a user</option>
                  {users.map((user) => (
                    <option key={user.userid} value={user.userid}>
                      {user.username} {user.phone}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {secondUsers.length > 0 && (
              <div className="form-group">
                <label>Select Second User</label>
                <select
                  className="form-control"
                  value={selectedSecondUser || ''}
                  onChange={(e) => setSelectedSecondUser(e.target.value)}
                >
                  <option value="">Select a second user</option>
                  {secondUsers.map((user) => (
                    <option key={user.userid} value={user.userid}>
                      {user.username} {user.phone}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {selectedUser && selectedSecondUser && (
              <button className="btn btn-primary" onClick={handlePair}>
                Pair Users
              </button>
            )}
          </div>
        </div>


        <div className="row ">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                {selectedTournament && (
                  <button
                    className={Boolean(challenge) ? `btn btn-success mb-3 mr-2` : `btn btn-info mb-3 mr-2`}
                    onClick={Allchallenge}
                  >
                    Pair User
                  </button>
                )}

                {selectedTournament && (
                  <button
                    className={Boolean(txn) ? `btn btn-success mb-3 mr-2` : `btn btn-info mb-3 mr-2`}
                    onClick={transactionHis}
                  >
                    Join User
                  </button>
                )}
                {selectedTournament && (
                  <button
                    className={Boolean(winners) ? `btn btn-success mb-3 mr-2` : `btn btn-info mb-3 mr-2`}
                    onClick={winnerUsers}
                  >
                    Winner User
                  </button>
                )}
                {selectedTournament && (
                  <button
                    className={Boolean(level) ? `btn btn-success mb-3 mr-2` : `btn btn-info mb-3 mr-2`}
                    onClick={LevelWise}
                  >
                    Level Wise
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        {Boolean(challenge) && (
          <div className="table-responsive">
            <h4 className="d-flex justify-content-center">PAIR USERS</h4>
            <table className="table" id='myTable'>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Creator</th>
                  <th>Accepter</th>
                </tr>
              </thead>
              <tbody>
                {challenge.map((game, key) => (
                  <tr key={key}>
                    <td>{key + 1}</td>
                    <td>
                      <div className="d-flex align-items-between">
                       
                          <span className="pl-2 d-flex align-items-center">{game.creater_name} / {game.creater_phone}
                            <a
                              href={`https://api.whatsapp.com/send?phone=${encodeURIComponent(game.creater_phone)}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="d-flex align-items-center"
                            >
                              <img className="numbercall ml-2" src={callimg} alt="WhatsApp" />
                            </a>
                          </span>
                       
                      </div>
                    </td>
                    <td>
                      <span className="pl-2 d-flex align-items-center">{game.joiner_name} / {game.joiner_phone}
                        <a
                          href={`https://api.whatsapp.com/send?phone=${encodeURIComponent(game.joiner_phone)}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="d-flex align-items-center"
                        >
                          <img className="numbercall ml-2" src={callimg} alt="WhatsApp" />
                        </a>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {Boolean(txn) && (
          <div className="table-responsive">
            <h4 className="d-flex justify-content-center">JOIN USERS</h4>
            <table className="table" id='myTable'>
              <thead>
                <tr>
                  <th>#</th>
                  <th>User Name</th>
                  <th>Mobile No.</th>
                  <th>Point</th>
                  <th>Level</th>
                  <th>Result</th>
                </tr>
              </thead>
              <tbody>
                {txn.map((game, key) => (
                  <tr key={key}>
                    <td>{key + 1}</td>
                    <td>
                      <span className="pl-2">{game.username} </span>
                    </td>
                    <td>
                    <div className="d-flex align-items-between">
                      <span className="pl-2 d-flex align-items-center">{game.phone}
                        <a
                          href={`https://api.whatsapp.com/send?phone=${encodeURIComponent(game.phone)}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="d-flex align-items-center"
                        >
                          <img className="numbercall" src={callimg} alt="WhatsApp" />
                        </a>
                      </span>
                      </div>
                    </td>
                    <td>
                      <span className="pl-2">{game.point}</span>
                    </td>
                    <td>
                      <span className="pl-2">{game.lavel}</span>
                    </td>

                    <td>
                      <span className="pl-2" style={{
                        color: game.result === "win" || game.result === "winn" ? "green" :
                          game.result === "lose" ? "red" :
                            game.result === "awaiting" ? "orange" : "black"
                      }}>{game.result}</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {Boolean(winners) && (
          <div className="table-responsive">
            <h4 className="d-flex justify-content-center">WINNER USERS</h4>
            <table className="table" id='myTable'>
              <thead>
                <tr>
                  <th>#</th>
                  <th>User Name</th>
                  <th>Mobile No.</th>
                  <th>Point</th>
                  <th>Result</th>
                  <th>Winner Prize</th>
                </tr>
              </thead>
              <tbody>
                {winners.map((game, key) => (
                  <tr key={key}>
                    <td>{key + 1}</td>
                    <td>
                      <span className="pl-2">{game.username} </span>
                    </td>
                    <td>
                    <div className="d-flex align-items-between">
                      <span className="pl-2 d-flex align-items-center">{game.phone}
                        <a
                          href={`https://api.whatsapp.com/send?phone=${encodeURIComponent(game.phone)}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="d-flex align-items-center"
                        >
                          <img className="numbercall" src={callimg} alt="WhatsApp" />
                        </a>
                      </span>
                      </div>
                    </td>
                    <td>
                      <span className="pl-2">{game.point}</span>
                    </td>
                    <td>
                      <span className="pl-2" style={{
                        color: game.result === "win" || game.result === "winn" ? "green" :
                          game.result === "lose" ? "red" :
                            game.result === "awaiting" ? "orange" : "black"
                      }}>{game.result}</span>
                    </td>
                    <td>
                      <input
                        style={{ width: "97px", height: "25px" }}
                        onChange={(e) => updatePrizeChange(game._id, e)}
                        type="text"
                        value={prizes[game._id] !== undefined ? prizes[game._id] : game.winnerPrize || ''}
                      />
                      &nbsp;&nbsp;
                      {game.winnerPrize <= 0 && (
                        <button
                          className="btn btn-primary"
                          onClick={() => updatePrize(game._id, prizes[game._id], game.userid || game.winnerPrize)}
                        >
                          Update
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {Boolean(level) && (
          <div className="table-responsive">
            <h4 className="d-flex justify-content-center">LEVEL WISE</h4>
            <table className="table" id='myTable'>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Level</th>
                  <th>Users</th>
                </tr>
              </thead>
              <tbody>
                {level.map((game, key) => (
                  <tr key={key}>
                    <td>{key + 1}</td>
                    <td>
                      <span className="pl-2">{game._id}</span>
                    </td>
                    <td>
                      <span className="pl-2">{game.count}</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

      </div>
    </div>
  );
}
