import axios from 'axios';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

export default function AddStore() {
    const history = useHistory();

    // State for tournament store details
    const [entryFees, setEntryFees] = useState('');
    const [totalUser, setTotalUser] = useState('');
    const [status, setStatus] = useState('');
    const [date, setDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');

    const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
    const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
    const nodeMode = process.env.NODE_ENV;
    const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

    // Handle the main form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            entryFees,
            totalUser,
            status,
            date,
            startTime,
            endTime,
        };

        const access_token = localStorage.getItem("token");
        const headers = {
            Authorization: `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        };

        try {
            const response = await axios.post(
                `${baseUrl}tournament-store`,
                data,
                { headers }
            );

            if (response.status === 200) {
                alert('Tournament Store added successfully!');
                // history.push('/some-other-page'); 
            }
        } catch (error) {
            console.error('Error adding tournament store:', error);
            alert('An error occurred while adding the tournament store.');
        }
    };

    return (
        <div>
            <h4 className='text-uppercase font-weight-bold my-3'>Add Tournament Store</h4>


            <form id="add_store_form" onSubmit={handleSubmit}>
                <div className="form-row">
                    <div className="form-group col-md-4">
                        <label htmlFor="entryFees" className='text-dark'>Entry Fees</label>
                        <input
                            type="number"
                            className="form-control"
                            id="entryFees"
                            name="entryFees"
                            placeholder="Entry Fees"
                            onChange={(e) => setEntryFees(e.target.value)}
                            value={entryFees}
                            required
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-4">
                        <label htmlFor="totalUser"  className='text-dark'>Total Users</label>
                        <input
                            type="number"
                            className="form-control"
                            id="totalUser"
                            name="totalUser"
                            placeholder="Total Users"
                            onChange={(e) => setTotalUser(e.target.value)}
                            value={totalUser}
                            required
                        />
                    </div>
                </div>
                <div className="form-row">
    <div className="form-group col-md-4">
        <label htmlFor="status" className='text-dark'>Status</label>
        <select
            id="status"
            name="status"
            className="form-control"
            onChange={(e) => setStatus(e.target.value)}
            value={status}
            required
        >
            <option value="">Select Status</option>
            <option value="1">Active</option>
            <option value="0">Inactive</option>
        </select>
    </div>
</div>

                <div className="form-row">
                    <div className="form-group col-md-4">
                        <label htmlFor="date"  className='text-dark'>Date</label>
                        <input
                            type="date"
                            className="form-control"
                            id="date"
                            name="date"
                            onChange={(e) => setDate(e.target.value)}
                            value={date}
                            required
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-4">
                        <label htmlFor="startTime"  className='text-dark'>Start Time</label>
                        <input
                            type="time"
                            className="form-control"
                            id="startTime"
                            name="startTime"
                            onChange={(e) => setStartTime(e.target.value)}
                            value={startTime}
                            required
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-4">
                        <label htmlFor="endTime"  className='text-dark'>End Time</label>
                        <input
                            type="time"
                            className="form-control"
                            id="endTime"
                            name="endTime"
                            onChange={(e) => setEndTime(e.target.value)}
                            value={endTime}
                            required
                        />
                    </div>
                </div>
                <div>
                    <button type="submit" className='btn btn-success'>Add Store</button>
                </div>
            </form>
        </div>
    );
}
