import React, { useState, useEffect } from 'react';
import axios from 'axios';

export default function AddStore() {
    const [levels, setLevels] = useState([{ level: '', amount: '', id: '' }]); // Default empty input
    const [leveldata, setLeveldata] = useState([]);
    const [levelid, setLevelIds] = useState([]);
    const [numberOfPerson, setNumberOfPerson] = useState('');
    const url = new URL(window.location.href);
    const idParam = url.searchParams.get('t_id');

    const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
    const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
    const nodeMode = process.env.NODE_ENV;
    const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;


    useEffect(() => {
        if (idParam) {
            fetchLevels();
        }
    }, [idParam]);

    const fetchLevels = async () => {
        const access_token = localStorage.getItem("token");
        const headers = {
            Authorization: `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        };

        try {
            const response = await axios.post(
                `${baseUrl}tournament-winner-prize-list-sep`,
                { tournamentid: idParam },
                { headers }
            );

            if (response.status === 200 && response.data.status) {
                const data = response.data.data;
                setLeveldata(data);

                if (data && data.length > 0) {
                    const fetchedLevels = data.map(level => ({
                        level: level.lavel,
                        amount: level.prize,
                        id: level._id
                    }));
                    setLevels(fetchedLevels);
                    setNumberOfPerson( response.data.data[0].numberofperson)
                    setLevelIds(data.map(level => level._id));
                } else {
                    setLevels([{ level: '', amount: '', id: '' }]);
                }
            } else {
                console.error('Failed to fetch levels:', response);
            }
        } catch (error) {
            console.error('Error fetching prize levels:', error);
        }
    };

    const handleLevelChange = (index, e) => {
        const values = [...levels];
        values[index][e.target.name] = e.target.value;
        setLevels(values);
    };

    const handleAddLevel = () => {
        setLevels([...levels, { level: '', amount: '', id: '' }]);
    };

    const handleRemoveLevel = async (index) => {
        const levelId = levels[index].id;
        const access_token = localStorage.getItem("token");
        const headers = {
            Authorization: `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        };

        try {
            const response = await axios.delete(
                `${baseUrl}tournament-winner-prize-delete/${levelId}`,
                { headers }
            );
            if (response.status === 200) {
                const updatedLevels = [...levels];
                updatedLevels.splice(index, 1);
                setLevels(updatedLevels);

                alert('Level deleted successfully!');
            }
            fetchLevels();
        } catch (error) {
            console.error('Error deleting level:', error);
            alert('Failed to delete level');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            tournamentlevels: levels,
            tournamentid: idParam,
            numberofperson: numberOfPerson,
        };

        const access_token = localStorage.getItem("token");
        const headers = {
            Authorization: `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        };

        try {
            const response = await axios.post(
                `${baseUrl}tournament-winner-prize-store`,
                data,
                { headers }
            );

            if (response.status === 200) {
                alert('Added Prize successfully!');
            }
            fetchLevels();
            // Refresh the levels after submit
        } catch (error) {
            console.error('Error adding tournament store:', error);
            alert('An error occurred while adding the tournament store.');
        }
    };

    return (
        <div>
            <div className="mt-5">
                <h5 className="text-dark">Levels and Amounts</h5>
                <div className='col-md-12'>
                    <div className="form-group col-md-4"> Number Of Person
                    </div>

                    <div className="form-group col-md-4">
                    <input
                    type="text"
                    className="form-control"
                    name="numberOfPerson"
                    placeholder="Number Of Person"
                    value={numberOfPerson}
                    onChange={(e) => setNumberOfPerson(e.target.value)}
                    required
                />
                    </div>
                    <div className="form-group col-md-4">
                        
                    </div>
                </div>
                <form onSubmit={handleSubmit}>
                    {levels.map((level, index) => (
                        <div key={index} className="form-row align-items-end mb-3">
                            <div className="form-group col-md-4">
                                <input
                                    type="text"
                                    className="form-control"
                                    id={`level-${index}`}
                                    name="level"
                                    value={level.level || ''}
                                    onChange={(e) => handleLevelChange(index, e)}
                                    placeholder="Level"
                                    required
                                />
                            </div>

                            <div className="form-group col-md-4">
                                <input
                                    type="number"
                                    className="form-control"
                                    id={`amount-${index}`}
                                    name="amount"
                                    value={level.amount || ''}
                                    onChange={(e) => handleLevelChange(index, e)}
                                    placeholder="Amount"
                                    required
                                />
                            </div>

                            <div className="col-md-4 mb-3 d-flex">
                                {index === levels.length - 1 && (
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={handleAddLevel}
                                    >
                                        Add More
                                    </button>
                                )}
                                <button
                                    type="button"
                                    className="btn btn-danger ml-2"
                                    onClick={() => handleRemoveLevel(index)}
                                    style={{ height: '35px' }}
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    ))}

                    <button type="submit" className="btn btn-primary">
                        Submit
                    </button>
                </form>
            </div>
        </div>
    );
}
